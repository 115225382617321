import React, {useContext, useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {Account} from "./Main"
import {server, debug_mode} from "./Config"
import Alert from './componenti/Alert'
import "./Profilo.css"
import "./Login.css"
import { SHA1 } from 'crypto-js';

const Profilo = () => {
    document.title="Sporting - Profilo"

    const [errore, seterrore] = useState([false,""]);
    const [campi_edit_psw, setCampi_edit_psw] = useState([])
    const [showpsw, setshowpsw] = useState("fa-solid fa-eye")
    const [btn_text, setbtn_text] = useState("Modifica password")

    const utente_user = useContext(Account);
    var nav=useNavigate()

    useEffect(() => {
        document.body.scrollTop = 0;
    }, []);

    const showPassword=()=>{
        let x=document.querySelector("#psw")
        if(x.type==="password"){
            x.type="text"
            setshowpsw("fa-solid fa-eye-slash")
        }else{
            x.type="password"
            setshowpsw("fa-solid fa-eye")
        }
    }

    const mod_psw=()=>{
        if(btn_text === "Aggiorna Password"){
            let username=utente_user.persona.username
            let password=document.querySelector("#psw").value
            let email=document.querySelector("#email_profilo").value

            if(debug_mode) console.log(email, password)

            if(email!=="" && email.match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            ) && password!=="" && password.match(/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{7,17}$/)){
                let link=server['url']+"cambia_password.php"
                let formDataMod = new FormData();
                formDataMod.append("username", username);
                formDataMod.append('email', email);
                formDataMod.append('nuova_password', SHA1(password).toString())
                localStorage.setItem("pwd",SHA1(password).toString())
                fetch(link,{ method: 'POST', body: formDataMod })
                .then(function (response) {
                    return response.text();
                })
                .then(function (body) {
                    if(debug_mode) console.log(body)
                    if(body.includes("modificata")){
                        setCampi_edit_psw([]);
                        seterrore([true,"Password modificata con successo"])
                        setTimeout(() => {
                            seterrore([false,""])
                        }, 1500);
                        setbtn_text("Modifica password")
                    }else{
                        seterrore([true,"Email errata"])
                        setTimeout(() => {
                            seterrore([false,""])
                        }, 1500);
                    }
                });
            }
            else{
                seterrore([true,"Dati assenti o password non valida"])
                setTimeout(() => {
                    seterrore([false,""])
                }, 1500);
            }
        }else{
            setbtn_text("Aggiorna Password")
            let el_em=
                <div className='campo' key={"em"}>
                    <span>Email</span>
                    <input type='text' name="eml" id="email_profilo" placeholder="esempio@esempio.it"></input>
                </div>
            let el_psw=
            <form key={"ps"} style={{width:"100%",display:"flex",justifyContent:"center"}}>
                <div className='campo psw'>
                    <span>Nuova password: (Almeno un numero, un carattere speciale e una lettera maiuscola, da 8 a 16 caratteri)</span>
                    <input type="password" autoComplete="on" name="password" id="psw"/>
                    <i className={showpsw} onClick={showPassword}></i>
                </div>
            </form>
            setCampi_edit_psw([el_em,el_psw])
        }
    }

    const check_input=(inp)=>{
        if(inp.nascita==="" || !inp.nascita.match(/^([0-9]{2})\/([0-9]{2})\/([0-9]{4})$/)){
            return {"stato":false,"errore":"Data di nascita errata"} 
        }
        return {"stato":true,"errore":""} 
    }

    const aggiorna_dati =()=>{
        let link=server['url']+"aggiorna_dati.php"
        let nascita=document.getElementById("nascita").value
        let socio=document.getElementById("socio").checked
        let giusto=check_input({nascita})
        if(giusto.stato){
            let aggiornati={nascita,socio}
            utente_user.setpersona(persona => ({
                cognome: persona.cognome,
                nome: persona.nome,
                email: persona.email,
                username: persona.username,
                ...aggiornati
            }));
            if(socio){
                localStorage.setItem("socio","true")
            }else{
                localStorage.setItem("socio","false")
            }
            let formDataMod = new FormData();
            formDataMod.append("username", localStorage.getItem("username"));
            formDataMod.append('password', localStorage.getItem("pwd"))
            formDataMod.append("nascita", nascita);
            formDataMod.append("socio", socio);
    
            fetch(link,{ method: 'POST', body: formDataMod })
            .then(function (response) {
                return response.text();
            })
            .then(function (body) {
                if(debug_mode) console.log(body)
                if(body.includes("MODIFICATO"))
                {
                    seterrore([true,"Dati aggiornati con successo"])
                    setTimeout(() => {
                        seterrore([false,""])
                    }, 1300);
                }
                else
                {
                    seterrore([true,"Si è verificato un errore"])
                    setTimeout(() => {
                        seterrore([false,""])
                    }, 1300);
                }
            })
        }
        else{
            seterrore([true,giusto.errore])
            setTimeout(() => {
                seterrore([false,""])
            }, 1500);
        }
    }

    const cancella_account=()=>{
        if (window.confirm('ATTENZIONE: Stai per eliminare il tuo account e le prenotazioni associate, vuoi procedere?')) {
            let link=server['url']+"elimina_account.php"

                let formDataCanc = new FormData();
                formDataCanc.append("username", localStorage.getItem("username"));
                formDataCanc.append('password', localStorage.getItem("pwd"))
        
                fetch(link,{ method: 'POST', body: formDataCanc })
                .then(function (response) {
                    return response.text();
                })
                .then(function (body) {
                    if(debug_mode) console.log(body)
                    if(body)
                    {
                        seterrore([true,"Account eliminato"])
                        setTimeout(() => {
                            seterrore([false,""])
                            localStorage.clear();
                            window.location.reload();
                        }, 1300);
                    }
                    else
                    {
                        seterrore([true,"Si è verificato un errore"])
                        setTimeout(() => {
                            seterrore([false,""])
                        }, 1300);
                    }
                })
        }
    }

    return (
        <div id="profilo">
            {errore[0] ? <Alert messaggio={errore[1]}/> : null}
            <div id="profilo-cont">
                <div id="user-logo">
                    <i className="fa-solid fa-user"></i>
                    <h1>{utente_user.persona.nome} {utente_user.persona.cognome}</h1>
                </div>
                <div id="user_dati_cont">
                    <h1>I tuoi dati:</h1>
                    <div id="dati_input">
                    <div className='dato campo distanza'>
                        <span>Username:</span>
                        <input type='text' disabled={true} name="username" id="username"  value= {utente_user.persona.username}/>
                    </div>
                    <div className='dato campo distanza'>
                        <span>Data di nascita:</span>
                        <input type='text' name="nascita" id="nascita"  defaultValue={utente_user.persona.nascita}/>
                    </div>
                    <div className='dato campo distanza'>
                        <span>Socio:</span>
                        <label className="switch">
                            {localStorage.getItem("socio")==="true" ? <input type="checkbox" id="socio" name="socio" defaultChecked={true}/> : <input type="checkbox" id="socio" name="socio" defaultChecked={false}/>}
                            <span className="slider round"></span>
                        </label>
                        <span></span>
                    </div>
                    {campi_edit_psw}
                    </div>
                    <input type="button" name="mod-psw" value="Aggiorna Dati" onClick={aggiorna_dati}/>
                    <input type="button" name="mod-psw" value={btn_text} onClick={mod_psw}/>
                    <input type="button" name="mod-psw" value="Esci" onClick={()=>{
                        nav("/")
                        setTimeout(() => {
                            document.querySelector("#user-nav").textContent="Accedi"
                            utente_user.setpersona({email:"",nome:"",cognome:"",username:"",nascita:""});
                            localStorage.clear()
                        }, 1);
                    }}/>
                    <br></br>
                    <br></br>
                    <input type="button" name="mod-psw" value="Cancella account" onClick={cancella_account}/>
                    
                </div>
            </div>
        </div>
    )
}

export default Profilo