import React from 'react'

const Informazioni = () => {
    const chiudi_info=()=>{
        document.querySelector("#informazioni").classList.add("chiuso")
    }
    return (
        <div id="informazioni" className='chiuso'>
            <i className="fa-solid fa-close" onClick={chiudi_info}></i>
            <div>
                <h1>Informazioni sulla prenotazione</h1>
                <h3>Non soci:</h3>
                <p>Possono prenotare solo 2 giorni prima. E' possibile prenotare 2 ore, di cui 1 in fascia grigia e una bianca, o entrambe bianche.</p>
                <h3>Soci:</h3>
                <p>Possono prenotare per la settimana in corso e quella successiva. E' possibile prenotare 2 ore, di cui 1 in fascia grigia e una bianca, o entrambe bianche.</p>
                <h3>Maestri:</h3>
                <p>Possono prenotare per la settimana in corso e quelle due successive, potendo prenotare qualsiasi ora.</p>
                <hr/>
                <h3>Prezzi:</h3>
                <b style={{fontSize:"0.9rem"}}>Lezioni da 1 ora (primo prezzo SOCI, secondo prezzo NON SOCI)</b>
                <ul>
                    <li>Lezione individuale: 22€/28€</li>
                    <li>Lezione in due: 12€/15€  (a testa)</li>
                    <li>Lezione in tre: 9€/12€  (a testa)</li>
                    <li>Lezione in quattro: 8€/10€  (a testa)</li>
                </ul>
                {/* <b style={{fontSize:"0.9rem"}}>Lezioni da 45' (MINI TENNIS 4-9 anni)</b>
                <ul>
                    <li>Lezione individuale: 15€</li>
                    <li>Lezione in due: 8€  (a testa)</li>
                    <li>Lezione in tre: 6€  (a testa)</li>
                    <li>Lezione in quattro: 5€  (a testa)</li>
                    <li>Lezione in cinque: 4€  (a testa)</li>
                </ul> */}
                {/* <b style={{fontSize:"0.9rem"}}>Quote tesseramenti (dal 28/03 al 30/10 2022):</b>
                <ul>
                    <li>UOMO: 190€</li>
                    <li>DONNA: 160€</li>
                    <li>UNDER 25 (uomo): 120€</li>
                    <li>UNDER 25 (donna): 100€</li>
                    <li>UNDER 20: 75€</li>
                    <li>UNDER 15: 30€</li>
                </ul> */}
            </div>
        </div>
    )
}

export default Informazioni